import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { useTranslation } from "gatsby-plugin-react-i18next"
import tusas1 from "../../assets/img/tusas-motor-1.jpg"
import tusas2 from "../../assets/img/tusas-motor-2.png"

export default function TusasMotorSanayii() {
  const { t } = useTranslation()
  return (
    <Layout>
      <SEO title="Tusaş Motor Sanayii" />
      <div className="projeler-container page">
        <div className="ic-proje">
          <img src={tusas1} alt="" className="proje-icGorsel" />
          <div className="proje-kunye">
            <span>{t("project-brand")}</span>: {t("tusas-motor")}
            <br />
            <span>{t("project-sector")}</span>: {t("aerospace")}
            <br />
            <span>{t("project-subject")}</span>: {t("tei-yorum")}
            <br />
            <span>{t("project-artwork")}</span>: {t("la-havle")}
          </div>
          <img src={tusas2} alt="" className="proje-icGorsel" />
        </div>
      </div>
    </Layout>
  )
}
